import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const CreateBill = Loadable(lazy(() => import('./createbill/CreateBill')));
const BillList = Loadable(lazy(() => import('./createbill/BillList')));
const BillSummary = Loadable(lazy(() => import('./createbill/BillSummary')));
const BillDetails = Loadable(lazy(() => import('./createbill/BillDetails')));
const BillUpdate = Loadable(lazy(() => import('./createbill/BillUpdate')));
const ManagePaid = Loadable(lazy(() => import('./paidbilty/ManagePaidBilty')));

const billRoutes = [
  { path: '/bill', element: <CreateBill /> },
  { path: '/billlist', element: <BillList /> },
  { path: '/billdetails', element: <BillDetails /> },
  { path: '/billupdate', element: <BillUpdate /> },
  { path: '/paidbilty', element: <ManagePaid /> },
  { path: '/billsummary', element: <BillSummary /> },
];

export default billRoutes;
